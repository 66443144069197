<template>
  <div class="pay">
    <div class="main">
      <div class="box">
        <div class="row">
          <span v-text="'课程名称'"></span>
          <em>{{ info.body }}</em>
        </div>
        <div class="row">
          <span>订单编号</span>
          <em>{{ order_id }}</em>
        </div>
        <div class="row">
          <span>商家名称</span>
          <em>聚亿美官微</em>
        </div>
        <div class="row">
          <span>课程数量</span>
          <van-stepper v-model="num" />
        </div>
        <div class="row">
          <span>课程价格</span>
          <span class="gold-num">￥{{ info.price }}元</span>
        </div>
      </div>

      <div class="box">
        <div class="row">
          <span>还需支付</span>
          <span class="gold-num">￥{{ info.price * num }}元</span>
        </div>
      </div>

      <div class="box">
        <h1>支付方式</h1>
        <div class="row way" @click="payWay('yue')">
          <span>
            余额支付
            <br />
            <sub>可用余额：￥{{ userInfo.now_money }}元</sub>
            <i class="jym-jifen1" style="color: #fe960f"></i>
          </span>
          <i class="jym-icon-test59"></i>
        </div>
        <div class="row way" @click="payWay('weixin')">
          <span>
            微信支付
            <br />
            <sub>微信支付，安全快捷</sub>
            <i class="jym-weixin"></i>
          </span>
          <i class="jym-icon-test59"></i>
        </div>
        <div class="row way" @click="payWay('alipay')">
          <span>
            支付宝支付
            <br />
            <sub>简单、安全、快速</sub>
            <i class="jym-zhifubao"></i>
          </span>
          <i class="jym-icon-test59"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { Stepper } from 'vant';
import {
  getUser,
  lessonCreatePhone,
  studentPay,
} from "@api/user";
import { isWeixin } from "@utils";
import { pay } from "@libs/wechat";
const _isWeixin = isWeixin();
Vue.use(Stepper);
export default {
  name: "apply",
  data() {
    return {
      id: "",
      num: 1,
      qrId: "",
      order_id: "",
      lesson_id: "",
      admin_id: "",
      is_network: "",
      teacher_id: "",
      info: {},
      userInfo: {},
    };
  },
  created() {
    this.lesson_id = this.$route.query.lesson_id
    this.admin_id = this.$route.query.admin_id
    this.is_network = this.$route.query.is_network
    this.teacher_id = this.$route.query.teacher_id
    this.qrId = this.$route.query.id
    window.location.href = `/testh5/pages/subcontract/activity/network_pay?admin_id=` + this.admin_id + "&lesson_id=" + this.lesson_id +"&is_network=" +  this.is_network +  "&teacher_id=" +  this.teacher_id

    // this.getData();
    // this.getUserInfo();
  },
  methods: {
    // 获取列表数据
    getData() {
      let that = this;
      const params = {
        id: that.qrId,
        lesson_id: that.lesson_id,
        teacher_id: that.teacher_id,
        admin_id: that.admin_id,
        is_network: that.is_network,
      };

      lessonCreatePhone(params)
        .then((res) => {
          that.$set(that, "info", res.data);
          that.$set(that, "id", res.data.id);
          that.$set(that, "order_id", res.data.order_id);
          that.$set(that, "num", res.data.child_num);
        })
        .catch((err) => {
          that.$dialog.error(err.msg);
        });
    },

    // 获取用户信息
    getUserInfo() {
      getUser()
        .then((res) => {
          this.userInfo = res.data;
        })
        .catch(() => {});
    },

    // 支付
    payWay(way) {
      let that = this,
        url = "/user/networkStatus",
        params = {
          id: that.id,
          num: that.num,
          payType: way,
          from: _isWeixin ? "weixin" : "weixinh5",
        };

      studentPay(params)
        .then((res) => {
          const data = res.data;
          switch (res.data.status) {
            case "PAY_ERROR":
              that.$dialog.error(res.msg || "创建订单失败");
              break;
            case "SUCCESS":
              that.$dialog.success(res.msg);
              that.$router.replace({
                path: url,
                query: { id: that.id, way: "yue" },
              });
              break;
            case "WECHAT_H5_PAY":
              that.$router.replace({
                path: url,
                query: { id: that.id, way: "weixin-h5" },
              });
              setTimeout(() => {
                location.href = data.result.jsConfig.mweb_url;
              }, 100);
              break;
            case "ALIPAY":
              if (isWeixin()) {
                // 微信端
                that.$router.push({
                  path: "/alipay",
                  query: {
                    url: data.result.body,
                    query: { id: that.id, way: "alipay" },
                  },
                });
              } else {
                setTimeout(() => {
                  location.href = data.result.body;
                }, 100);
                that.$router.replace({
                  path: url,
                  query: { id: that.id, way: "alipay" },
                });
              }
              break;
            case "WECHAT_PAY":
              pay(data.result.jsConfig).finally(() => {
                this.$router.replace({
                  path: url,
                  query: { id: that.id, way: "weixin" },
                });
              });
              break;
          }
        })
        .catch((err) => {
          that.$dialog.error(err.msg || "创建订单失败");
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.pay {
  .main {
    .box {
      margin-top: 0.2rem;
      font-size: 0.28rem;
      background: white;
      h1 {
        font-size: 0.28rem;
        padding-left: 0.3rem;
        line-height: 0.4rem;
        padding-top: 0.2rem;
        font-weight: 500;
      }
      .row {
        padding: 0 0.3rem;
        height: 0.88rem;
        line-height: 0.88rem;
        display: flex;
        justify-content: space-between;
        border-bottom: 0.02rem solid #dedede;
        em {
          font-family: PingFang SC;
          color: #a9a9a9;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          max-width: 5.5rem;
        }
        > i {
          font-size: 0.4rem;
        }
      }
      .row:last-child {
        border: none;
      }
    }
    .box:first-child {
      margin: 0;
    }
    .way {
      height: 0.98rem !important;
      span {
        font-size: 0.26rem;
        line-height: 0.32rem;
        padding: 0.17rem 0 0.17rem 0.68rem;
        position: relative;
        sub {
          font-size: 0.2rem;
          color: #a9a9a9;
        }
        i {
          position: absolute;
          font-size: 0.54rem;
          top: 0.32rem;
          left: 0;
        }
        .jym-weixin {
          color: #00c800;
        }
        .jym-zhifubao {
          color: #02a9f1;
        }
      }
    }
  }
}
</style>
